<template>
  <div class="wrapper">
    <Menu></Menu>
    <Slider></Slider>

    <div class="body mt-1">

      <Highlights></Highlights>

      <div class="sport_dropdowns">

        <!-- Sports dropdown -->
        <div class="btn-group">

          <button
              style="background-color: #e9e9e9;font-size: 1em;font-weight: bold;"
              class="btn btn-secondary btn-sm dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              v-text="sport_name">
          </button>

          <div class="dropdown-menu">

          <span
              v-for="s in sports"
              v-bind:key="getKey(s.sport_id)"
              class="dropdown-item sport_dropdown_item black-txt"
              @click="setSport(s)"
              v-text="s.sport_name">
          </span>

          </div>

        </div>

        <!-- Top Leagues dropdown -->

      </div>

      <div class="countries">

        <div v-for="(c,index) in outrightData" v-bind:key="getCountryKey(index)" class="countries-item">

          <div @click="loadData(c.country_code)" class="trend-item p-1" v-bind:class="selectedClass(c.country_code)">

            <div class="trend-text1">
              <lazy-image
                  v-if="c.country_code.length > 0"
                  class="lozad"
                  v-bind:alt="c.category_name"
                  v-bind:src="getCountryFlag(c.country_code)"
                  style="width: 15px"/>
              {{ c.category_name }}
            </div>

            <div class="trend-text1">
              {{ c.outright_count }}
              <span style="position: relative;top: 6px;">
                  <arrow-right-icon style="height: 1em;"></arrow-right-icon>
                </span>
            </div>
          </div>

          <Tournament
              v-show="isVisisble(c.country_code)"
              v-bind:leagues="c.tournaments"
              v-bind:category_name="c.category_name"
              v-bind:sport_id="sport_id"
              v-bind:load="isLoading(c.country_code)"/>
        </div>

      </div>

      <div class="timeline-wrapper" v-show="busy">

        <div class="timeline-item">

          <div class="animated-background facebook">

            <!-- lines -->

            <div class="background-masker" style="top: 0px; left: 0px; width: 60%; height: 7px; "></div>

            <div class="background-masker" style="top: 17px; left: 0px; width: 60%; height: 7px; "></div>

            <div class="background-masker" style="top: 34px; left: 0px; width: 60%; height: 7px; "></div>

            <div class="background-masker" style="top: 51px; left: 0px; width: 60%; height: 7px; "></div>

            <div class="background-masker" style="bottom: 0px; left: 0px; width: 60%; height: 5px; "></div>

            <!-- odds separator -->
            <div class="background-masker" style="top: 0; bottom: 0; left: 59%; width: 1%; "></div>

            <div class="background-masker" style="top: 0px; right: 0px; width: 40%; height: 7px; "></div>
            <div class="background-masker" style="bottom: 0px; right: 0px; width: 40%; height: 7px; "></div>

            <!-- odds separator -->
            <div class="background-masker" style="top: 0; bottom: 0; left: 79%; width: 1%; "></div>


          </div>

        </div>

      </div>
      <div class="timeline-wrapper" v-show="busy">

        <div class="timeline-item">

          <div class="animated-background facebook">

            <!-- lines -->

            <div class="background-masker" style="top: 0px; left: 0px; width: 60%; height: 7px; "></div>

            <div class="background-masker" style="top: 17px; left: 0px; width: 60%; height: 7px; "></div>

            <div class="background-masker" style="top: 34px; left: 0px; width: 60%; height: 7px; "></div>

            <div class="background-masker" style="top: 51px; left: 0px; width: 60%; height: 7px; "></div>

            <div class="background-masker" style="bottom: 0px; left: 0px; width: 60%; height: 5px; "></div>

            <!-- odds separator -->
            <div class="background-masker" style="top: 0; bottom: 0; left: 59%; width: 1%; "></div>

            <div class="background-masker" style="top: 0px; right: 0px; width: 40%; height: 7px; "></div>
            <div class="background-masker" style="bottom: 0px; right: 0px; width: 40%; height: 7px; "></div>

            <!-- odds separator -->
            <div class="background-masker" style="top: 0; bottom: 0; left: 79%; width: 1%; "></div>


            <!--        <div class="background-masker header-top" style="background-color: red"></div>-->
            <!--        <div class="background-masker header-left"></div>-->
            <!--        <div class="background-masker header-right">g</div>-->
            <!--        <div class="background-masker header-bottom">h</div>-->

            <!--        <div class="background-masker subheader-left">i</div>-->
            <!--        <div class="background-masker subheader-right">j</div>-->
            <!--        <div class="background-masker subheader-bottom">k</div>-->

            <!--        <div class="background-masker content-top">l</div>-->

          </div>

        </div>

      </div>

      <BottomFooter></BottomFooter>
      <bottom-navigation active-bets="0" page="outright"></bottom-navigation>

    </div>

  </div>
</template>

<script>
const Highlights = () => import('@/components/Highlights.vue')
const Slider = () => import('@/components/Slider.vue')
const BottomFooter = () => import('@/components/BottomFooter.vue')
const Menu = () => import('@/components/Menu.vue')
const BottomNavigation = () => import('@/components/outright/BottomNavigation.vue')
const Tournament = () => import('@/components/outright/Tournament.vue')
import axios from "@/services/api";


export default {
  name: 'Outright',
  components: {
    Menu,
    Slider,
    Highlights,
    BottomFooter,
    BottomNavigation,
    Tournament,
  },
  data: function () {
    return {
      sub_page: '',
      loading: [],
      visible: [],
      is_busy: false,
      busy: false,
      sports: [],
      countries: [],
      outrights: [],
      mqttClient: false,
      dates: [],
      sport_name: 'Sports',
      selected_hour: 0,
      hours: [-1, 1, 3, 6, 12, 24, 48],
      sport_id: 1
    }
  },
  mounted() {

    this.$store.dispatch("setCurrentPage", "outright");
    this.resetFilters();

    this.reloadProfile();
    this.getOutright();

    var d0 = this.daysFromNow(0);
    var d1 = this.daysFromNow(1);
    var d2 = this.daysFromNow(2);
    //var d3 = this.daysFromNow(3);
    //var d4 = this.daysFromNow(4);

    this.dates.push({
      date: d0,
      label: this.getDayOfWeek(d0)
    });

    this.dates.push({
      date: d1,
      label: this.getDayOfWeek(d1)
    });

    this.dates.push({
      date: d2,
      label: this.getDayOfWeek(d2)
    });

    /*
    this.dates.push({
      date: d3,
      label: this.getDayOfWeek(d3)
    });


    this.dates.push({
      date: d4,
      label: this.getDayOfWeek(d4)
    });
     */

  },
  computed: {

    betslip_count: function () {

      return this.$store.state.outrightbetslip.total

    },
    current_sub_page: function () {

      return this.$store.state.current_sub_page;
    },

    market_id: function () {
      return this.$store.state.market_id;
    },
    hour: function () {

      return -1;
      // this.$store.state.hour;
    },
    date: function () {

      return this.$store.state.upcoming_date

    },
    outrightData: function () {

      var vm = this;
      var d = [];

      vm.jQuery.each(vm.outrights,function(k,v){

        if(parseInt(v.sport_id) === vm.sport_id ) {

          vm.jQuery.each(v.categories,function (x,y){

            if (y.tournaments.length > 0) {
              d.push(y)
            }

          });

        }

      });

      return d;

    }
  },
  methods: {

    getKey: function (index) {

      return Math.random().toString(20).replace('0.', 'random-id-' + index + '-');

    },

    setHour: function (h) {

      this.selected_hour = h;
      this.$store.dispatch("setHour", h);

    },
    humanHourName: function (h) {

      if (h === -1) {

        return 'All Time'
      }

      return h === '' || h === "" || h === 0 ? 'Time' : h + ' Hours'

    },

    getOutright: function () {

      var vm = this;
      var path = process.env.VUE_APP_URL_OUTRIGHT;

      vm.busy = true;
      axios.post(path, JSON.stringify({}))
          .then(res => {

            vm.busy = false;

            vm.outrights = res.data.message;

            vm.sports = [];

            vm.jQuery.each(vm.outrights,function(k,v){

              vm.sports.push({
                sport_id: v.sport_id,
                sport_name: v.sport_name
              });

            });



          })
          .catch(error => {

            console.log(error)

          })
    },
    isLoading: function (index) {

      const i = this.loading.indexOf(index);
      if (i > -1) {
        return true;

      }

      return false;
    },
    selectedClass: function (index) {

      const i = this.visible.indexOf(index);
      if (i > -1) {
        return 'selected';
      }

      return '';
    },
    isVisisble: function (index) {

      const i = this.visible.indexOf(index);
      if (i > -1) {

        return true;

      }
      return false;

    },
    loadData: function (index) {

      const i = this.loading.indexOf(index);

      if (i > -1) {  // index already exisits in loading array

        // toggle visibility
        const i = this.visible.indexOf(index);

        // check if its visible
        if (i > -1) {

          this.visible.splice(i, 1); // hide visibility

        } else {

          this.visible.push(index); // else make visible

        }

      } else {

        this.loading.push(index);
        // make visible
        this.visible.push(index);
      }

    },
    getCountryKey: function (index) {

      var prefix = 'outrights-'+index;
      return Math.random().toString(10).replace('0.', 'sport-id-' + prefix + '-');

    },
    setSport: function (sport) {

      this.sport_name = sport.sport_name;
      this.sport_id = sport.sport_id;

    },
    geChildPageActiveClass: function (childpage) {

      return childpage === this.$store.state.upcoming_date ? 'selected-date' : "";

    },
    setUpcomingDate: function (date) {

      this.$store.dispatch("setUpcomingDate", date)

    },
    setUpcomingAll: function () {

      this.$store.dispatch("setUpcomingDate", '')

    },
    getDayName: function (i, label) {

      console.log('index ==> ' + i + ' label ==> ' + label);

      if (i === 0) {

        return 'Today';
      }

      if (i === 1) {

        return 'Tomorrow';
      }

      return label;

    },
  },
}
</script>